export default () => {
  $('.marker-pin').each(function() {
    let getOffsetX = $(this).data('x');
    let getOffsetY = $(this).data('y');

    $(this).css({
      "left": getOffsetX, 
      "top": getOffsetY
    });
  });
};
