export default () => {
  let activeTab = window.location.hash;
  if(activeTab.includes("land")){
    $('#land-tab').trigger('click');
  }
  
  $('.customer-land-favorite').on('click', function(){
    $('span[data-target="#navbarMainMenu"]').trigger('click');
    $('#land-tab').trigger('click');
  });
}