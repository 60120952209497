export default () => {
  // $('.custom-bs-dropdowns .dropdown-toggle').on('click', function(event) {
  //     $('.custom-bs-dropdowns .dropdown-menu').slideUp();
  //     $(this).siblings('.dropdown-menu').slideToggle();
  //     event.stopPropagation();
  // });

  $('.custom-bs-dropdowns .dropdown-menu').on('click', function(event) {
    event.stopPropagation();
  });

  // $(window).on('click', function() {
  //     $('.custom-bs-dropdowns .dropdown-menu').slideUp();
  // });
};
