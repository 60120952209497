export default () => {
  $('body')
      .on('mouseenter', '.news-card-columns .news-media', function(){
        $(this).find('img').css({'transform': 'scale(1.04)'});
      }).on('mouseleave', '.news-card-columns .news-media', function(){
        $(this).find('img').css({'transform': 'scale(1)'});
      }).on('mousemove', '.news-card-columns .news-media', function(e){
        $(this)
          .find('img')
            .css({'transform-origin': ((e.pageX - $(this).offset().left) / $(this).width()) * 100 + '% ' + ((e.pageY - $(this).offset().top) / $(this).height()) * 100 +'%'});
      });
}