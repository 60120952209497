import 'bootstrap';
import header from './components/header';
import initGLightbox from './components/glightbox';
import rangeSlider from './components/rangeSlider';
import tooltip from './components/tooltip';
import customDropdowns from './components/dropdown';
import modal from './components/modal';
import toggleDocsView from './components/documents';
import toggleCheckAllCustomers from './components/customer';
import fixedTable from './components/fixedTable';
import slickSliders from './components/slick';
import popovers from './components/popover';
import markerPin from './components/marker-pin';
import flyOut from './components/flyout';
import mapHilight from './components/maphilight';
// import locomotiveScroll from './components/locomotiveScroll';
import aosAnimation from './components/aos';

// Pages
import home from './pages/home';
import landSales from './pages/landSales';
import gallery from './pages/gallery';
import team from './pages/team';
import customer from './pages/customer';
import location from './pages/location';


$(document).ready(function () {
    header();
    initGLightbox();
    tooltip();
    toggleDocsView();
    customDropdowns();
    toggleCheckAllCustomers();
    modal();
    rangeSlider.init();
    fixedTable();
    slickSliders.init();
    popovers();
    markerPin();
    flyOut();

    // init mapHilight for land sale
    mapHilight.init({
        selector: '#map-overview',
    });

    // init mapHilight for gallery
    mapHilight.init({
        selector: '#gallery-overview',
        customClass: 'gallery-pin',
        icon: '',
        text: true,
    });
    // locomotiveScroll();
    aosAnimation();

    // Pages
    home();
    landSales();
    gallery();
    team();
    customer();
    location();
});