let flyoutAllPanels = $(".flyout-search-slots");
let flyoutSearchSlots = $("#flyout-search-slots");
let flyoutSlotDetail = $("#flyout-slot-detail");

let toggleSlotDetail = $('.toggle-slot-detail');

let stageOverlaysSVG = $('.stage-overlays-svg');
let stageOverlaysSearch = $('.stage-overlays-search');
let stageLotDetails = $('.stage-lot-details');

// map 
const mainMap = $('.wrapper-map');

// Process stage
const saleStage = $(".sale-stage");

function toggleFlyout(selector) {
  flyoutAllPanels.removeClass('is-active');
  selector.addClass('is-active');
}

function stageHover(el) {
  let stage = $(el).data('stage');
  $(`[data-stage=${stage}]`).toggleClass('hover');
}

export default () => {
  $(".toggle-search-slots").on('click', function(e) {
    e.preventDefault();
    toggleFlyout(flyoutSearchSlots);
    stageLotDetails.hide();
    stageOverlaysSearch.show();
  });

  toggleSlotDetail.on('click', function(e) {
    e.preventDefault();
    toggleFlyout(flyoutSlotDetail);
    stageOverlaysSVG.hide();
    stageOverlaysSearch.hide();

    let getLot = $(this).data('id');
    $(`#slot-${getLot}`).show();
  });

  $(".close-search-slots").on('click', function(e) {
    e.preventDefault();
    flyoutSearchSlots.removeClass('is-active');
  });

  saleStage.mouseover(function(){
    stageHover($(this));
  }).mouseout(function(){
    stageHover($(this));
  });

  saleStage.on('click', function(e){
    e.preventDefault();
    let getStage = $(this).data('stage');
    $('main, .wrap-content-stage').addClass('stage-active');
    $(`#content-stage-${getStage}`).show()
                                   .siblings('.content-stage')
                                   .hide();

    mainMap.addClass('is-active');
  });
};
