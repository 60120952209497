export default () => {
  const checkAllCheckbox = function(checkbox, items){
    $(checkbox).off('click').on('click', function(){
      let checkAll = $(this).prop('checked');
  
      if(checkAll) {
          $(items).prop('checked', true);
      } else {
          $(items).prop('checked', false);
      }
    });
  }

  const phoneValidate = function(){
    $(".validate-phone").on('input', function(e) { 
      $(this).val($(this).val().replace(/[^0-9 +-]/g, '')); 
    });  
  }
  phoneValidate();

  // check all documents
  checkAllCheckbox('.checkbox-docs','.checkbox-docs-item');

  $('#formCheckAll').off('click').on('click', function(){
    let checkAll = $(this).prop('checked');

    if(checkAll) {
        $('.list-customers input[type=checkbox]').prop('checked', true);
    } else {
        $('.list-customers input[type=checkbox]').prop('checked', false);
    }
  });


};
