import 'slick-carousel';

const mainSlider = $('.virtual-slider-main');
const thumbSlider = $('.virtual-slider-nav');
const toggleVirtualNav = $('#toggle-virtual-nav');
const virtualTourTab = $('#virtualTour-tab');

const slickSliders = {
  create() {
    mainSlider.slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      fade: true,
      asNavFor: '.virtual-slider-nav'
    });

    thumbSlider.slick({
      slidesToShow: 3,
      slidesToScroll: 1,
      centerMode: true,
      focusOnSelect: true,
      arrows: false,
      asNavFor: '.virtual-slider-main',
      responsive: [
        {
          breakpoint: 768,
          settings: {
            variableWidth: true,
            slidesToShow: 3,
          }
        }
      ]
    });

    toggleVirtualNav.off('click').on('click', (e) => {
      $(e.currentTarget).toggleClass('collapsed');
      thumbSlider.slideToggle();
      thumbSlider.slick('setPosition');
    });

    virtualTourTab.on("shown.bs.tab", function(e) {
      mainSlider.slick('setPosition');
      thumbSlider.slick('setPosition');
    });
  },

  init() {
    this.create();
  }
};

export default slickSliders;