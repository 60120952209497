export default () => {
  function hideAllModals() {
    $('.modal').modal('hide');
  }

  $('.delete-docs').on('click', function(){
    hideAllModals();
    // show the delete modal
    $('#modalDeleteConfirm').modal('show');
  });

  $('.customer-provile-view').on('click', function(){
    hideAllModals();
    // show the delete modal
    $('#modalUserProfile').modal('show');
  });

  $('.fitToLot-action').on('click', function(){
    hideAllModals();
    // show the delete modal
    $('#modalFitToLot').modal('show');
  });

  $('.compare-action').on('click', function(){
    hideAllModals();
    // show the delete modal
    $('#modalCompare').modal('show');
  });
};
