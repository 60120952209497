import throttle from 'lodash/throttle';

export default () => {
  let $window = $(window);
  let $menu = $('#navbarMainMenu');

  function checkWidth() {
      var windowsize = $window.width();
      if (windowsize < 992) {
        $menu.addClass('navbar-mobile');
      } else {
        $menu.removeClass('navbar-mobile');
      }
  }
  // Execute on load
  checkWidth();

  // Bind event listener
  $(window).resize(checkWidth);

  (function() {
    'use strict';
  
    let mainContent = document.querySelector("body");
    let header = document.querySelector("header");
    let headerHeight;
  
    function setTopPadding() {
      headerHeight = header.offsetHeight;
      mainContent.style.paddingTop = headerHeight + "px";
    }
  
    function onScroll() {
  
      window.addEventListener("scroll", throttle(callbackFunc, 200));

      /**
       * Replace the line above with the following one 
       * to see how many more times the "callbackFunc" is executed
       */
  
      function callbackFunc() {
        let pageYOffset = window.pageYOffset;
        if (pageYOffset > headerHeight) {
          header.classList.add("scroll");
        } else {
          header.classList.remove("scroll");
        }
      }
    }
  
    window.onload = function() {
      setTopPadding();
      onScroll();
    };
  
    window.onresize = function() {
      setTopPadding();
    };
  })();

  // Toggle menu on mobile
  $('.navbar-toggler').on('click', function(){
    $('#navbarMainMenu').toggleClass('show');
  });
}