export default () => {
  $('#toggleVolume').on('click', function(){
    const _video = $('.home-video video ');
    let getProp = _video.prop('muted');

    if(getProp) {
      $(this).html('<i class="fas fa-volume-up"></i>');
    } else {
      $(this).html('<i class="fas fa-volume-mute"></i>');
    }
    _video.prop('muted', !getProp);
  })
}