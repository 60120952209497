export default () => {
  let fcBody = document.querySelector(".fix-column > .tbody");
  let rcBody = document.querySelector(".rest-columns > .tbody");
  let rcHead = document.querySelector(".rest-columns > .thead");

  if(rcBody) {
    rcBody.addEventListener("scroll", function() {
      fcBody.scrollTop = this.scrollTop;
      rcHead.scrollLeft = this.scrollLeft;
    }, { passive: true });
  }
};
