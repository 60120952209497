import 'maphilight';

const mapHilight = {
  createMap(selector) {
    $(selector).maphilight({
      strokeColor: 'f05740',
      strokeWidth: 2,
      fill: true,
      fillColor: 'ffffff',
      fillOpacity: 1,
      alwaysOn: false,
      neverOn: true,
      wrapClass: 'map-canvas',
    });
  },

  createIcons({selector, customClass = '', icon = '<i class="fas fa-plus"></i>', text = false}) {
    $('area').each((_index, element) => {
      let type = $(element).attr('class');
      let title = $(element).attr('title')
      let position = $(element).attr('coords').split(',');
      let radius = +position[2];
      let posTop = +position[1] - radius;
      let posLeft = +position[0] - radius;

      let template =  `
        <div data-point="${type}" class="slot-ulti-pin ${customClass}" style="top: ${posTop}px; left: ${posLeft}px;">
          ${text ? `<span class="pint-text">${title}</span>` : ''}
          <span class="pin-icon" style="width: ${radius * 2}px; height: ${radius * 2}px; font-size: ${radius * 2 - 20}px">${icon}</span>
        </div>
      `;
      $(selector).parent().append(template);
    });
  },

  init(options) {
    this.createMap(options.selector);
    this.createIcons(options);
  }
};

export default mapHilight;