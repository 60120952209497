export default () => {
  $('.toggle-docs-view a').on('click', function(){
    let type = $(this).attr('data-type');

    $(this).siblings().removeClass('active');
    $(this).addClass('active');

    if(type == 'grid') {
        $('.docs-grid-view').show();
        $('.docs-list-view').hide();
    } else {
        $('.docs-list-view').show();
        $('.docs-grid-view').hide();
    }
  });
};
