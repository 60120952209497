import GLightbox from 'glightbox';

export default () => {
  const lightboxElements = $('.glightbox');
  const customLightboxHTML = `<div id="glightbox-body" class="glightbox-container">
      <div class="gloader visible"></div>
      <div class="goverlay"></div>
      <div class="gcontainer">
      <div id="glightbox-slider" class="gslider"></div>
      <button class="gnext gbtn" tabindex="0" aria-label="Next" data-customattribute="example"><i class="fas fa-chevron-right"></i></button>
      <button class="gprev gbtn" tabindex="1" aria-label="Previous"><i class="fas fa-chevron-left"></i></button>
      <button class="gclose gbtn" tabindex="2" aria-label="Close"><i class="fas fa-times"></i></button>
  </div>
  </div>`;

  lightboxElements.each((index, element) => {
      let title = $(element).attr('data-title');
      if (!title) return;
      $(element).attr('data-title', `${title} <span>${index + 1} / ${lightboxElements.length}</span>`);
  });

  const defaultGLightbox =  GLightbox({
      lightboxHTML: customLightboxHTML
  });

  const galleryGligghtbox = GLightbox({
    lightboxHTML: customLightboxHTML,
    selector: '.localtion-glightbox',
  });

  const lightboxPDF = GLightbox({
    'selector': '.lightboxPDF'
  });
};
