export default () => {
  // let flyoutAllPanels = $(".flyout-search-slots");
  // let flyoutSearchSlots = $("#flyout-search-slots");
  // let flyoutSlotDetail = $("#flyout-slot-detail");

  // function toggleFlyout(selector) {
  //   flyoutAllPanels.removeClass('is-active');
  //   selector.addClass('is-active');
  // }

  // function isMobile() {
  //   return window.matchMedia("only screen and (min-width: 768px)").matches;
  // }

  // if (isMobile()) {
  //   toggleFlyout(flyoutSearchSlots);
  // }

  // $(".toggle-search-slots").on('click', function() {
  //   toggleFlyout(flyoutSearchSlots);
  // });

  // $(".toggle-slot-detail").on('click', function() {
  //   toggleFlyout(flyoutSlotDetail);
  // });

  // $(".close-search-slots").on('click', function() {
  //   flyoutSearchSlots.removeClass('is-active');
  // });
}
