import 'slick-carousel';

export default () => {
  // gallery slide
  const galleryMapLink = $('.gallery-map-link');
  const galleryLength = parseInt(galleryMapLink.length);
  const firstSlideGallery = galleryMapLink.first();
  const lastSlideGallery = galleryMapLink.last();

  galleryMapLink.on('click', function(){
    galleryMapLink.removeClass('is-active');
    $(this).addClass('is-active');

    let activeSlide = galleryMapLink.index(this);
    let prevSlide = $(this).prev().length ? $(this).prev() : lastSlideGallery;
    let nextSlide = $(this).next().length ? $(this).next() : firstSlideGallery;

    let imgUrl = $(this).data('imgurl');
    let imgTitle = $(this).data('title');
    let imageOverlay = $('.image-overlay');

    if(imageOverlay.length) {
      imageOverlay.find('img').attr({
        'src': imgUrl,
        'alt': imgTitle
      });
      updateDotsActive(activeSlide);
    } else {
      imageOverlay = `
        <div class="image-overlay">
          <span class="btn-gallery-slide btn-gallery-slide--prev"><i class="fas fa-chevron-left"></i></span>
          <img src="${imgUrl}" alt="${imgTitle}">
          <span class="btn-gallery-slide btn-gallery-slide--next"><i class="fas fa-chevron-right"></i></span>
        </div>
      `;
      $('.gallery-map').before(imageOverlay);
      createDotsPaging(galleryLength, activeSlide);
    }

    $('.btn-gallery-slide--prev').off('click').on('click', () => {
      prevSlide.trigger('click');
    });
  
    $('.btn-gallery-slide--next').off('click').on('click', () => {
      nextSlide.trigger('click');
    });
  });

  function createDotsPaging(slides, active = 0) {
    let dots = `
      <ul class="gallery-dots">
        ${Array(slides).fill().map((item, i) => `
          <li class="gallery-dot ${i == active ? 'is-active' : ''}"></li>
        `).join('')}
      </ul>
    `;
    $('.image-overlay').append(dots);
  }

  function updateDotsActive(activeSlide) {
    $('.gallery-dots li').removeClass('is-active');
    $(`.gallery-dots li:nth-child(${activeSlide + 1})`).addClass('is-active');
  }

  // Back to gallery
  $('#back-to-gallery').on('click', () => {
    $('.image-overlay').remove();
  });

  // Open modal when click on the pin
  $('.gallery-pin').on('click', () => {
    $('.modal').modal('hide');
    $('#galleryPointQuickview').modal('show');
  });

  // Init slider and reInit when open modal
  $('#galleryPointQuickview').on('shown.bs.modal', () => {
    $('.gallery-quickview-content-left').slick('setPosition');
  })
  $('.gallery-quickview-content-left').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    dots: true,
  });
}