export default () => {
    $(".btn-toggle-sidebar").on('click', function() {
      let barInner = $(this).parents(".toggle-sidebar").find(".bar-inner");
      let btnSidebar = $(this).parents(".toggle-sidebar").find(".btn-sidebar");
        
      barInner.toggleClass("active");
      btnSidebar.toggleClass("open");
    });

    $('.map-item').on('click', function(e) {
      e.preventDefault();
      $('.map-item').removeClass('active');
      $(this).addClass('active');

      $('.imgage-full-location').hide();
      var target = $(this).data('target');
      $(target).show();
  });
}