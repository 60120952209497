import wNumb from 'wNumb';

const rangeSliders = {
  rangeSlider(selector, min = 0, max = 100, prefix = '', suffix = '', thousand = ',') {
    const slider = document.getElementById(selector);
    if(!slider) return;
    
    $(slider).parent().prepend(`<div class="fs-12 text-center mb-2" id="${selector}-value"></div>`);
    const stepSliderValueElement = document.getElementById(`${selector}-value`);
    let minValue = parseInt(slider.dataset.min);
    let maxValue = parseInt(slider.dataset.max);
    let prefixValue = slider.dataset.prefix;
    let suffixValue = slider.dataset.suffix;
    let thousandValue = slider.dataset.thousand;

    // Add value via the data attribute
    min = minValue ? minValue : min;
    max = maxValue ? maxValue : max;
    prefix = prefixValue ? prefixValue : prefix;
    suffix = suffixValue ? suffixValue : suffix;
    thousand = thousandValue ? thousandValue : thousand;
    
    noUiSlider.create(slider, {
        start: [min, max],
        connect: true,
        step: 1,
        range: {
            'min': min,
            'max': max
        },
        format: wNumb({
          decimals: 0,
          thousand: thousand,
          prefix: prefix,
          suffix: suffix
        })
    });

    slider.noUiSlider.on('update', function (values, handle) {
      stepSliderValueElement.innerHTML = values.join('<span class="mx-3">-</span>');
    });
  },

  init() {
    // Home and Land Sale
    this.rangeSlider('range-slider-area', 450, 1000, '', ' m2');
    this.rangeSlider('range-slider-budget', 600, 3400, '$ ', 'k');
    this.rangeSlider('range-slider-bedrooms', 2, 6);
    this.rangeSlider('range-slider-bathrooms', 2, 6);

    this.rangeSlider('range-slider-house-size', 2, 6);
    this.rangeSlider('range-slider-price', 600, 3400, '$ ', 'k');
    this.rangeSlider('range-slider-car-parks', 0, 4);

    // Flyout Search
    this.rangeSlider('range-slider-search-frontage');
    this.rangeSlider('range-slider-search-area');
    this.rangeSlider('range-slider-search-budget');
  }
};

export default rangeSliders;